import { render, staticRenderFns } from "./FourthStep.vue?vue&type=template&id=80c29eae&scoped=true&"
import script from "./FourthStep.vue?vue&type=script&lang=js&"
export * from "./FourthStep.vue?vue&type=script&lang=js&"
import style0 from "./FourthStep.vue?vue&type=style&index=0&id=80c29eae&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80c29eae",
  null
  
)

export default component.exports