<template>
  <div class="first-box">
    <a-form-model :model="form" :rules="rules">
      <a-form-model-item
        label="手机号"
        prop="phone"
        class="phone-item"
        :colon="false"
      >
        <a-input class="input-box" v-model="form.phone" />
      </a-form-model-item>
      <div class="last-box">
        <a-form-model-item label="验证码" prop="code" :colon="false">
          <a-input class="input-box-code" v-model="form.code" />
        </a-form-model-item>
        <a-button
          type="primary"
          :disabled="btnDisable"
          class="code-btn"
          @click="sendCode"
          >{{ btnDisable ? btnText : "发送验证码" }}</a-button
        >
      </div>
      <a-form-model-item label="你的姓名" prop="name" required :colon="false">
        <a-input v-model="form.name"></a-input>
      </a-form-model-item>
      <a-form-model-item label="登录用户名" prop="accountName" :colon="false">
        <a-input v-model="form.accountName"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="登录密码"
        prop="password"
        required
        :colon="false"
      >
        <a-input-password v-model="form.password"> </a-input-password>
      </a-form-model-item>
      <a-form-model-item
        label="再次输入"
        prop="passwordAgain"
        required
        :colon="false"
      >
        <a-input-password v-model="form.passwordAgain"> </a-input-password>
      </a-form-model-item>
      <div class="agree-box">
        <a-checkbox :checked="agreeBox" @change="changeBox" class="check-box" />
        <div @click="checkChange" class="agree-txt">我已阅读并同意</div>
        <div><span>《服务协议》</span><span>《隐私协议》</span></div>
      </div>
      <a-button
        type="primary"
        @click="nextStep"
        class="next-btn"
        :disabled="!nextStatus"
        >下一步</a-button
      >
    </a-form-model>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        phone: "",
        code: "",
        name: "",
        accountName: "",
        password: "",
        passwordAgain: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
      },
      agreeBox: false,
      btnDisable: false,
      time: 60,
    };
  },
  methods: {
    changeBox(e) {
      this.agreeBox = e.target.checked;
      console.log(e);
    },
    checkChange() {
      this.agreeBox = !this.agreeBox;
    },
    nextStep() {
      this.$emit("nextStep", 3);
    },
    sendCode() {
      this.btnDisable = true;
      let timer = setInterval(() => {
        if (this.time == 0) {
          clearInterval(timer);
          this.btnDisable = false;
          this.time = 60;
        } else {
          this.time--;
        }
      }, 1000);
    },
  },
  computed: {
    btnText() {
      return this.time;
    },
    nextStatus() {
      return !!(
        this.form.code &&
        this.form.phone &&
        this.agreeBox &&
        this.form.name &&
        this.form.password &&
        this.form.passwordAgain
      );
    },
  },
};
</script>
<style lang="less" scoped>
.first-box {
  width: 316px;
  margin-top: 42px;
  /deep/.ant-form-item-label {
    line-height: 21px;
  }
  /deep/.input-box {
    width: 296px;
    height: 32px;
  }
  /deep/.input-box-code {
    width: 152px;
    height: 32px;
    // margin-top: 8px;
  }
  /deep/.code-btn {
    width: 136px;
    height: 32px;
    font-size: 12px;
    margin-top: 20px;
  }
  /deep/.next-btn {
    width: 100%;
    height: 40px;
    font-size: 16px;
    margin-top: 24px;
  }
  .last-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .agree-box {
    display: flex;
    font-size: 12px;
    color: #515561;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    .agree-txt {
      margin-left: 4px;
    }
    // /deep/.check-box {
    //   width: 16px;
    //   height: 16px;
    // }
    span {
      color: #048fff;
    }
  }
}
/deep/.ant-form-item {
  margin-bottom: 5px;
}
</style>