<template>
  <div class="over-box">
    <div class="alert-msg">新商家创建成功！</div>
    <a-button type="primary" class="btn" @click="toLogin"
      >完成并登录商家版</a-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
.over-box {
  width: 296px;
  margin-top: 42px;
  .alert-msg {
    font-size: 18px;
    color: #252b3a;
  }
  .btn {
    width: 100%;
    height: 40px;
    margin-top: 32px;
    font-size: 16px;
  }
}
</style>