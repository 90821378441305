<template>
  <div class="max-box">
    <div class="nav-box">
      <div class="nav-content">
        <div class="nav-left">
          <img class="navbar-logo" src="../../assets/logo/register-logo.png" />
          <span class="version">商家版</span>
          <span class="reg-txt">注册</span>
        </div>
        <span class="nav-right" @click="toLogin">已有商家版账号？登录</span>
      </div>
    </div>
    <div class="section-box">
      <div class="step-box">
        <a-steps :current="current">
          <a-step description="完善商家信息" />
          <a-step description="完善账号信息" />

          <a-step description="注册成功" />
        </a-steps>
      </div>
      <div class="message-ctn">
        <component :is="activeComponents" @nextStep="nextTop"></component>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from "./components/FirstStep.vue";
import SecondStep from "./components/SecondStep.vue";
import ThirdStep from "./components/ThirdStep.vue";
import FourthStep from "./components/FourthStep.vue";
export default {
  data() {
    return {
      current: 0,
      componentsList: ["FirstStep", "SecondStep", "ThirdStep"],
      activeComponents: "",
    };
  },
  components: { ThirdStep, FirstStep, SecondStep },
  methods: {
    nextTop(id) {
      this.$router.push(`/register/${id}`);
    },
    toLogin() {
      this.$router.push("/login");
    },
  },
  watch: {
    $route(to, from) {
      this.current = this.$route.params.id - 1;
      this.activeComponents = this.componentsList[this.$route.params.id - 1];
      console.log(this.$route.params.id);
    },
  },
  mounted() {
    this.current = this.$route.params.id - 1;
    this.activeComponents = this.componentsList[this.$route.params.id - 1];
  },
};
</script>
<style lang="less" scoped>
.max-box {
  width: 100%;
  background: #f0f0f1;
  height: 100vh;
  .nav-box {
    height: 65px;
    width: 100%;
    background: #fff;
    .nav-content {
      width: 70%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-left {
        display: flex;
        align-items: center;
        .navbar-logo {
          width: auto;
          height: 24px;
        }
        .version {
          font-size: 22px;
          margin-left: 8px;
        }
        .reg-txt {
          font-size: 28px;
          padding-left: 4px;
          margin-left: 8px;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
      .nav-right {
        font-size: 12px;
        padding-right: 12px;
        cursor: pointer;
        &:hover {
          color: #048fff;
        }
      }
    }
  }
  .section-box {
    width: 55%;
    margin: 0 auto;
    background: #fff;
    margin-top: 24px;
    padding: 32px 0 32px 0;
    box-shadow: 0 16px 80px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(37, 43, 58, 0.1);
    border-radius: 4px;
    min-height: 532px;
    .step-box {
      width: 70%;
      margin: 0 auto;
    }
    .message-ctn {
      display: flex;
      justify-content: center;
    }
    /deep/.ant-steps-item-title {
      width: 1px;
      height: 32px;
      padding-right: 0px;
    }
    /deep/.ant-steps-item-description {
      font-size: 12px;
    }
    /deep/.ant-steps-item-active .ant-steps-item-description {
      color: #048fff;
    }
  }
}
</style>