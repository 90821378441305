<template>
  <div class="merchant-box">
    <a-form-model :model="form" :rules="rules">
      <a-form-model-item label="商家名称" prop="merchantName">
        <a-input class="merchantName" v-model="form.merchantName"></a-input>
      </a-form-model-item>
      <a-form-model-item label="商家联系人" prop="concat">
        <a-input class="merchantName" v-model="form.concat"></a-input>
      </a-form-model-item>
      <a-form-model-item label="商家联系手机" prop="telphone">
        <a-input class="merchantName" v-model="form.telphone"></a-input>
      </a-form-model-item>
      <!-- <a-form-model-item label="类型" prop="type">
        <a-radio-group v-model="form.type">
          <a-radio :value="1"> 安装运维商 </a-radio>
          <a-radio :value="2"> 投资商 </a-radio>
          <a-radio :value="3"> 分销商 </a-radio>
          <a-radio :value="4"> 设备商 </a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <div class="btn-box">
        <a-button
          type="primary"
          :disabled="!btnDisabled"
          class="btn"
          @click="nextStep"
          >下一步</a-button
        >
      </div>
    </a-form-model>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        merchantName: "",
        concat: "",
        telphone: "",
      },
      rules: {},
    };
  },
  computed: {
    btnDisabled() {
      return this.form.merchantName && this.form.concat && this.form.telphone;
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", 2);
    },
  },
};
</script>
<style lang="less" scoped>
.merchant-box {
  width: 60%;
  margin-top: 42px;
  /deep/.ant-form-item-label {
    line-height: 23px;
  }
  /deep/.ant-form-item {
    margin-bottom: 24px;
  }
  .district-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .merchantName {
      width: 325px;
    }
    .district-box {
      width: 200px;
    }
  }
  .btn-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  /deep/.btn {
    width: 296px;
    height: 40px;
    font-size: 16px;
  }
}
</style>